<template>
  <div class="container-fluid pt-64 pt-md-80 pb-80 pb-md-0">
    <div class="row pt-0 pt-md-32">
      <div class="col-md-8">
        <div class="card mb-16 mb-md-12">
          <div class="card__title">
            <span class="fs18 lh28 fw500 d-block">
              Order Info
            </span>
          </div>

          <div class="card__content">
            <div class="mw596 mx-auto">
              <div class="row">
                <div class="col">
                  <b-form-group
                    id="warehouse-field"
                    label="Warehouse"
                    label-for="warehouse"
                  >
                    <multiselect
                      id="warehouse"
                      v-model="value"
                      :options="warehouses"
                      :searchable="false"
                      :show-labels="false"
                    ></multiselect>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group
                    id="warehouse-address-field"
                    label="Address"
                    label-for="warehouse-address"
                    :description="
                      'Characters: ' +
                        warehouseAddress.length +
                        ' / ' +
                        this.maxLength
                    "
                  >
                    <div class="input-group-addon">
                      <b-form-textarea
                        id="warehouse-address"
                        type="text"
                        v-model="warehouseAddress"
                        placeholder="Enter warehouse address"
                        :maxlength="this.maxLength"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="mode-field" label="Mode" label-for="mode">
                    <multiselect
                      id="mode"
                      v-model="value"
                      :options="warehouses"
                      :searchable="false"
                      :show-labels="false"
                    ></multiselect>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group id="type-field" label="Type" label-for="type">
                    <multiselect
                      id="type"
                      v-model="value"
                      :options="warehouses"
                      :searchable="false"
                      :show-labels="false"
                    ></multiselect>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group
                    id="orderid-field"
                    label="Order ID"
                    label-for="order-id"
                  >
                    <b-form-input
                      id="order-id"
                      placeholder="Enter order id"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-md-6">
                  <b-form-group id="date-field" label="Date" label-for="date">
                    <date-picker
                      prefix-class="xmx"
                      v-model="date"
                      id="date"
                      placeholder="yyyy-mm-dd"
                    >
                      <i slot="icon-calendar" class="icon-calendar"></i>
                    </date-picker>
                  </b-form-group>
                </div>
                <div class="col-6 col-md-6">
                  <b-form-group
                    id="total-value-field"
                    label="Total Value"
                    label-for="total-value"
                  >
                    <b-input-group append="₹">
                      <b-input
                        id="total-value"
                        placeholder="0.00"
                        v-money="money"
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-16 mb-md-12">
          <div class="card__title">
            <span class="fs18 lh28 fw500 d-block">
              Customer Info
            </span>
          </div>
          <div class="card__content">
            <div class="mw596 mx-auto">
              <div class="row">
                <div class="col">
                  <b-form-group
                    id="customer-name-field"
                    label="Name"
                    label-for="customer-name"
                  >
                    <b-form-input
                      id="customer-name"
                      placeholder="Enter customer name"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group
                    id="phone-field"
                    label="Phone number"
                    label-for="phone-number"
                  >
                    <b-input-group>
                      <b-form-input
                        id="phone-number"
                        placeholder="123 456 7890"
                        type="tel"
                      ></b-form-input>
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <i class="icon-phone fs16 text-gray-600"></i>
                        </div>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="city-field" label="City" label-for="city">
                    <b-form-input
                      id="city"
                      placeholder="Enter city"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group id="pin-field" label="Pin Code" label-for="pin">
                    <b-form-input
                      id="pin"
                      placeholder="10000"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group
                    id="customer-address-field"
                    label="Address"
                    label-for="customer-address"
                    :description="
                      'Characters: ' +
                        customerAddress.length +
                        ' / ' +
                        this.maxLength
                    "
                  >
                    <div class="input-group-addon">
                      <b-form-textarea
                        id="customer-address"
                        type="text"
                        v-model="customerAddress"
                        placeholder="Enter customer address"
                        :maxlength="this.maxLength"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-16 mb-md-12">
          <div class="card__title">
            <span class="fs18 lh28 fw500 d-block pb-12">
              Package Details
            </span>
          </div>
          <div class="card__content">
            <b-table
              class="dark-header"
              :fields="fields"
              :items="items"
              responsive
            >
              <!-- A custom formatted header cell for field 'selected' -->
              <template v-slot:head(selected)>
                <div class="d-flex justify-content-center align-items-center">
                  <b-checkbox
                    v-model="allSelected"
                    @change="toggleAllRows"
                  ></b-checkbox>
                </div>
              </template>

              <!-- Example scoped slot for select state illustrative purposes -->
              <template v-slot:cell(selected)="{ item, field: { key } }">
                <div class="d-flex justify-content-center align-items-center">
                  <b-checkbox v-model="item[key]"></b-checkbox>
                </div>
              </template>

              <!-- A custom formatted header cell for field 'dimensions' -->
              <template v-slot:head(dimensions)>
                <span class="underline-dashed">
                  Dimensions
                </span>
                <i
                  class="icon-tutorial ml-4 vam text-gray-400"
                  v-b-tooltip.hover.right
                  title="I am dimension explanation!"
                ></i>
              </template>
              <!-- A custom formatted header cell for field 'sku' -->
              <template v-slot:head(sku)>
                SKU
              </template>
              <!-- A custom formatted header cell for field 'quantity' -->
              <template v-slot:head(quantity)>
                QTY.
              </template>
              <!-- A custom formatted header cell for field 'value' -->
              <template v-slot:head(value)>
                <span>Value</span>
              </template>
              <!-- A custom formatted cell for field 'dimensions' -->
              <template v-slot:cell(dimensions)="data">
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="data.item.dimensions.width"
                    placeholder="10"
                    class="mh28 w40"
                  ></b-form-input>
                  <span class="px-4">x</span>
                  <b-form-input
                    v-model="data.item.dimensions.height"
                    placeholder="10"
                    class="mh28 w40"
                  ></b-form-input>
                  <span class="px-4">x</span>
                  <b-form-input
                    v-model="data.item.dimensions.length"
                    placeholder="10"
                    class="mh28 w40"
                  ></b-form-input>
                </div>
              </template>
              <!-- A custom formatted cell for field 'weight' -->
              <template v-slot:cell(weight)="data">
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="data.item.weight"
                    placeholder="10"
                    class="d-inline-flex mh28 w40"
                  ></b-form-input>
                  <span class="ml-4">
                    kg
                  </span>
                </div>
              </template>
              <!-- A custom formatted cell for field 'weight' -->
              <template v-slot:cell(quantity)="data">
                <b-form-input
                  v-model="data.item.quantity"
                  placeholder="10"
                  class="d-inline-flex mh28 w40"
                ></b-form-input>
              </template>
              <!-- A custom formatted cell for field 'value' -->
              <template v-slot:cell(value)="data">
                <div class="d-flex align-items-center">
                  <span class="mr-4">
                    ₹
                  </span>
                  <b-form-input
                    v-model="data.item.value"
                    placeholder="10"
                    class="d-inline-flex mh28 w72"
                  ></b-form-input>
                </div>
              </template>
            </b-table>
          </div>
        </div>

        <div class="d-block mb-16 text-right d-md-none">
          <b-button @click="goBack" variant="secondary" size="lg" class="mr-16">
            Cancel
          </b-button>
          <b-button @click="goBack" variant="primary" size="lg" class="mr-16">
            Save and Book
          </b-button>
          <b-button @click="goBack" variant="primary" size="lg">
            Save
          </b-button>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card top100 rounded sticky-top p-24 bg-white">
          <div class="row">
            <div class="col">
              <span class="fs18 lh28 fw500 d-block pb-20">
                Package — How to’s
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <ul class="lsnone m-0 p-0">
                <li class="pb-24">
                  <span class="fs16 lh19 fw500 d-block pb-4">
                    1. Dimensions
                  </span>
                  <span class="text-gray-600 d-block pb-8">
                    Dimensions must follow the sequence:
                  </span>
                  <div class="rounded px-12 py-10 bg-light-orange">
                    <span>
                      Length
                      <span class="text-gray-600">x</span>
                      Width
                      <span class="text-gray-600">x</span>
                      Height
                    </span>
                  </div>
                </li>
                <li>
                  <span class="fs16 lh19 fw500 d-block pb-4">2. Weight</span>
                  <span class="text-gray-600 d-block pb-8">
                    When entering weight values, you need to:
                  </span>
                  <div class="rounded px-12 py-10 bg-light-orange">
                    <span>Weight per x1 item. Not weight for all items.</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
  name: "ShipmentsEditForm",
  data() {
    return {
      customerAddress: "",
      warehouseAddress: "",
      maxLength: 150,
      date: null,
      value: null,
      allSelected: false,
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      fields: [
        {
          key: "selected",
          sortable: false,
          class: "px-12 py-10",
        },
        {
          key: "sku",
          sortable: true,
          class: "pl-0",
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "dimensions",
          label: "Dimensions",
        },
        {
          key: "weight",
          sortable: true,
        },
        {
          key: "quantity",
          sortable: true,
        },
        {
          key: "value",
          sortable: true,
          class: "pr-0",
        },
      ],
    };
  },
  components: {
    DatePicker,
    Multiselect,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toggleAllRows() {
      if (this.allSelected == false) {
        this.items.forEach((item) => {
          this.$set(item, "selected", true);
          this.allSelected = true;
        });
      } else {
        this.selectedRows.forEach((item) => {
          this.$delete(item, "selected");
          this.allSelected = false;
        });
      }
    },
  },
  computed: {
    selectedRows() {
      return this.items.filter((item) => item.selected);
    },
    items() {
      return this.$store.getters.shipmentEditItems;
    },
    warehouses() {
      return this.$store.getters.warehouses;
    },
  },
  watch: {
    selectedRows: function() {
      if (this.selectedRows.length == this.items.length) {
        this.allSelected = true;
      }
    },
  },
};
</script>
