<template>
  <div class="shipments-edit">
    <AppTopbar title="Edit Order" hideSearchNotif>
      <template v-slot:left-button>
        <b-button @click="goBack" variant="primary mr-8" class="d-none d-md-inline-flex">
          Save
        </b-button>
        <b-button @click="goBack" variant="primary mr-8" class="d-none d-md-inline-flex">
          Save and Book
        </b-button>
        <b-button to="/new-order" class="d-none d-md-inline-flex">
          Create New
        </b-button>
      </template>
      <template v-slot:right-button>
        <b-button variant="rounded" size="sm" @click="goBack">
          <i class="icon-close-variant"></i>
        </b-button>
      </template>
    </AppTopbar>

    <ShipmentsEditForm />
  </div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import ShipmentsEditForm from "@/components/ShipmentEditForm.vue";

export default {
  name: "ShipmentsEdit",
  title: "Shyplite - Edit",
  components: {
    AppTopbar,
    ShipmentsEditForm,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
