var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shipments-edit" },
    [
      _c("AppTopbar", {
        attrs: { title: "Edit Order", hideSearchNotif: "" },
        scopedSlots: _vm._u([
          {
            key: "left-button",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-flex",
                    attrs: { variant: "primary mr-8" },
                    on: { click: _vm.goBack }
                  },
                  [_vm._v(" Save ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-flex",
                    attrs: { variant: "primary mr-8" },
                    on: { click: _vm.goBack }
                  },
                  [_vm._v(" Save and Book ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-flex",
                    attrs: { to: "/new-order" }
                  },
                  [_vm._v(" Create New ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "right-button",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "rounded", size: "sm" },
                    on: { click: _vm.goBack }
                  },
                  [_c("i", { staticClass: "icon-close-variant" })]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("ShipmentsEditForm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }