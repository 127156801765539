var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid pt-64 pt-md-80 pb-80 pb-md-0" },
    [
      _c("div", { staticClass: "row pt-0 pt-md-32" }, [
        _c("div", { staticClass: "col-md-8" }, [
          _c("div", { staticClass: "card mb-16 mb-md-12" }, [
            _vm._m(0),
            _c("div", { staticClass: "card__content" }, [
              _c("div", { staticClass: "mw596 mx-auto" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "warehouse-field",
                            label: "Warehouse",
                            "label-for": "warehouse"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              id: "warehouse",
                              options: _vm.warehouses,
                              searchable: false,
                              "show-labels": false
                            },
                            model: {
                              value: _vm.value,
                              callback: function($$v) {
                                _vm.value = $$v
                              },
                              expression: "value"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "warehouse-address-field",
                            label: "Address",
                            "label-for": "warehouse-address",
                            description:
                              "Characters: " +
                              _vm.warehouseAddress.length +
                              " / " +
                              this.maxLength
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "input-group-addon" },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "warehouse-address",
                                  type: "text",
                                  placeholder: "Enter warehouse address",
                                  maxlength: this.maxLength,
                                  rows: "3",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.warehouseAddress,
                                  callback: function($$v) {
                                    _vm.warehouseAddress = $$v
                                  },
                                  expression: "warehouseAddress"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "mode-field",
                            label: "Mode",
                            "label-for": "mode"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              id: "mode",
                              options: _vm.warehouses,
                              searchable: false,
                              "show-labels": false
                            },
                            model: {
                              value: _vm.value,
                              callback: function($$v) {
                                _vm.value = $$v
                              },
                              expression: "value"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "type-field",
                            label: "Type",
                            "label-for": "type"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              id: "type",
                              options: _vm.warehouses,
                              searchable: false,
                              "show-labels": false
                            },
                            model: {
                              value: _vm.value,
                              callback: function($$v) {
                                _vm.value = $$v
                              },
                              expression: "value"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "orderid-field",
                            label: "Order ID",
                            "label-for": "order-id"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "order-id",
                              placeholder: "Enter order id"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "date-field",
                            label: "Date",
                            "label-for": "date"
                          }
                        },
                        [
                          _c(
                            "date-picker",
                            {
                              attrs: {
                                "prefix-class": "xmx",
                                id: "date",
                                placeholder: "yyyy-mm-dd"
                              },
                              model: {
                                value: _vm.date,
                                callback: function($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-calendar",
                                attrs: { slot: "icon-calendar" },
                                slot: "icon-calendar"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "total-value-field",
                            label: "Total Value",
                            "label-for": "total-value"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { append: "₹" } },
                            [
                              _c("b-input", {
                                directives: [
                                  {
                                    name: "money",
                                    rawName: "v-money",
                                    value: _vm.money,
                                    expression: "money"
                                  }
                                ],
                                attrs: {
                                  id: "total-value",
                                  placeholder: "0.00"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "card mb-16 mb-md-12" }, [
            _vm._m(1),
            _c("div", { staticClass: "card__content" }, [
              _c("div", { staticClass: "mw596 mx-auto" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "customer-name-field",
                            label: "Name",
                            "label-for": "customer-name"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "customer-name",
                              placeholder: "Enter customer name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "phone-field",
                            label: "Phone number",
                            "label-for": "phone-number"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "phone-number",
                                  placeholder: "123 456 7890",
                                  type: "tel"
                                }
                              }),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c("div", { staticClass: "input-group-text" }, [
                                  _c("i", {
                                    staticClass: "icon-phone fs16 text-gray-600"
                                  })
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "city-field",
                            label: "City",
                            "label-for": "city"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "city", placeholder: "Enter city" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "pin-field",
                            label: "Pin Code",
                            "label-for": "pin"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "pin",
                              placeholder: "10000",
                              type: "number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "customer-address-field",
                            label: "Address",
                            "label-for": "customer-address",
                            description:
                              "Characters: " +
                              _vm.customerAddress.length +
                              " / " +
                              this.maxLength
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "input-group-addon" },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "customer-address",
                                  type: "text",
                                  placeholder: "Enter customer address",
                                  maxlength: this.maxLength,
                                  rows: "3",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.customerAddress,
                                  callback: function($$v) {
                                    _vm.customerAddress = $$v
                                  },
                                  expression: "customerAddress"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "card mb-16 mb-md-12" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "card__content" },
              [
                _c("b-table", {
                  staticClass: "dark-header",
                  attrs: {
                    fields: _vm.fields,
                    items: _vm.items,
                    responsive: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "head(selected)",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center"
                            },
                            [
                              _c("b-checkbox", {
                                on: { change: _vm.toggleAllRows },
                                model: {
                                  value: _vm.allSelected,
                                  callback: function($$v) {
                                    _vm.allSelected = $$v
                                  },
                                  expression: "allSelected"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(selected)",
                      fn: function(ref) {
                        var item = ref.item
                        var key = ref.field.key
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center"
                            },
                            [
                              _c("b-checkbox", {
                                model: {
                                  value: item[key],
                                  callback: function($$v) {
                                    _vm.$set(item, key, $$v)
                                  },
                                  expression: "item[key]"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "head(dimensions)",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "underline-dashed" }, [
                            _vm._v(" Dimensions ")
                          ]),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.right",
                                modifiers: { hover: true, right: true }
                              }
                            ],
                            staticClass: "icon-tutorial ml-4 vam text-gray-400",
                            attrs: { title: "I am dimension explanation!" }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "head(sku)",
                      fn: function() {
                        return [_vm._v(" SKU ")]
                      },
                      proxy: true
                    },
                    {
                      key: "head(quantity)",
                      fn: function() {
                        return [_vm._v(" QTY. ")]
                      },
                      proxy: true
                    },
                    {
                      key: "head(value)",
                      fn: function() {
                        return [_c("span", [_vm._v("Value")])]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(dimensions)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("b-form-input", {
                                staticClass: "mh28 w40",
                                attrs: { placeholder: "10" },
                                model: {
                                  value: data.item.dimensions.width,
                                  callback: function($$v) {
                                    _vm.$set(data.item.dimensions, "width", $$v)
                                  },
                                  expression: "data.item.dimensions.width"
                                }
                              }),
                              _c("span", { staticClass: "px-4" }, [
                                _vm._v("x")
                              ]),
                              _c("b-form-input", {
                                staticClass: "mh28 w40",
                                attrs: { placeholder: "10" },
                                model: {
                                  value: data.item.dimensions.height,
                                  callback: function($$v) {
                                    _vm.$set(
                                      data.item.dimensions,
                                      "height",
                                      $$v
                                    )
                                  },
                                  expression: "data.item.dimensions.height"
                                }
                              }),
                              _c("span", { staticClass: "px-4" }, [
                                _vm._v("x")
                              ]),
                              _c("b-form-input", {
                                staticClass: "mh28 w40",
                                attrs: { placeholder: "10" },
                                model: {
                                  value: data.item.dimensions.length,
                                  callback: function($$v) {
                                    _vm.$set(
                                      data.item.dimensions,
                                      "length",
                                      $$v
                                    )
                                  },
                                  expression: "data.item.dimensions.length"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(weight)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("b-form-input", {
                                staticClass: "d-inline-flex mh28 w40",
                                attrs: { placeholder: "10" },
                                model: {
                                  value: data.item.weight,
                                  callback: function($$v) {
                                    _vm.$set(data.item, "weight", $$v)
                                  },
                                  expression: "data.item.weight"
                                }
                              }),
                              _c("span", { staticClass: "ml-4" }, [
                                _vm._v(" kg ")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(quantity)",
                      fn: function(data) {
                        return [
                          _c("b-form-input", {
                            staticClass: "d-inline-flex mh28 w40",
                            attrs: { placeholder: "10" },
                            model: {
                              value: data.item.quantity,
                              callback: function($$v) {
                                _vm.$set(data.item, "quantity", $$v)
                              },
                              expression: "data.item.quantity"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "cell(value)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("span", { staticClass: "mr-4" }, [
                                _vm._v(" ₹ ")
                              ]),
                              _c("b-form-input", {
                                staticClass: "d-inline-flex mh28 w72",
                                attrs: { placeholder: "10" },
                                model: {
                                  value: data.item.value,
                                  callback: function($$v) {
                                    _vm.$set(data.item, "value", $$v)
                                  },
                                  expression: "data.item.value"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "d-block mb-16 text-right d-md-none" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-16",
                  attrs: { variant: "secondary", size: "lg" },
                  on: { click: _vm.goBack }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mr-16",
                  attrs: { variant: "primary", size: "lg" },
                  on: { click: _vm.goBack }
                },
                [_vm._v(" Save and Book ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", size: "lg" },
                  on: { click: _vm.goBack }
                },
                [_vm._v(" Save ")]
              )
            ],
            1
          )
        ]),
        _vm._m(3)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "fs18 lh28 fw500 d-block" }, [
        _vm._v(" Order Info ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "fs18 lh28 fw500 d-block" }, [
        _vm._v(" Customer Info ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "fs18 lh28 fw500 d-block pb-12" }, [
        _vm._v(" Package Details ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c(
        "div",
        { staticClass: "card top100 rounded sticky-top p-24 bg-white" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("span", { staticClass: "fs18 lh28 fw500 d-block pb-20" }, [
                _vm._v(" Package — How to’s ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("ul", { staticClass: "lsnone m-0 p-0" }, [
                _c("li", { staticClass: "pb-24" }, [
                  _c("span", { staticClass: "fs16 lh19 fw500 d-block pb-4" }, [
                    _vm._v(" 1. Dimensions ")
                  ]),
                  _c("span", { staticClass: "text-gray-600 d-block pb-8" }, [
                    _vm._v(" Dimensions must follow the sequence: ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "rounded px-12 py-10 bg-light-orange" },
                    [
                      _c("span", [
                        _vm._v(" Length "),
                        _c("span", { staticClass: "text-gray-600" }, [
                          _vm._v("x")
                        ]),
                        _vm._v(" Width "),
                        _c("span", { staticClass: "text-gray-600" }, [
                          _vm._v("x")
                        ]),
                        _vm._v(" Height ")
                      ])
                    ]
                  )
                ]),
                _c("li", [
                  _c("span", { staticClass: "fs16 lh19 fw500 d-block pb-4" }, [
                    _vm._v("2. Weight")
                  ]),
                  _c("span", { staticClass: "text-gray-600 d-block pb-8" }, [
                    _vm._v(" When entering weight values, you need to: ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "rounded px-12 py-10 bg-light-orange" },
                    [
                      _c("span", [
                        _vm._v("Weight per x1 item. Not weight for all items.")
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }